<template>
  <div>
    <div class="d-sm-block d-md-none mb-2 ml-2 topbar">

      <div
        v-for="item in menu"
        :key="item.to"
        class="d-inline"
      >
        <b-button
          variant="sectionmobile"
          :class="{active: active(item.subsection)}"
          :to="item.to"
        >
          <font-awesome-icon
            :icon="item.icon"
            class="fa-2x align-middle"
          />
          <span class="ml-2 d-none d-sm-inline">{{ item.title }}</span>
        </b-button>
      </div>

    </div>

    <div
      class="sidebar sidebar-menu d-none d-md-block"
      id="sidebar-menu"
    >
      <div
        class="d-flex align-items-center justify-content-end sidebar-control"
        @click="toggleNav"
      >
        <div class="p-2">
          <font-awesome-icon
            v-if="open"
            icon="fa-light fa-arrow-left"
            size="lg"
            class="mr-4"
          />
          <font-awesome-icon
            v-else
            icon="fa-light fa-arrow-right"
            size="lg"
            class="mr-2"
          />
        </div>
      </div>

      <div
        v-for="
            item
            in
            menu"
        :key="item.to"
      >
        <router-link
          :to="item.to"
          tag="div"
        >
          <div
            :class="{active: active(item.subsection)} "
            class="d-flex flex-row mb-3 mt-4 sidebar-item cursor-pointer"
          >
            <div class="ml-2 p-2 d-flex justify-content-center">
              <font-awesome-icon
                :icon="item.icon"
                class="fa-lg sidebar-icon align-middle"
                fixed-width
              />
            </div>
            <div
              v-if="open"
              class="p-2"
            >{{ item.title }}</div>
          </div>
        </router-link>

      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: 'side-bar-menu',
  props: ['menu'],
  data() {
    return {
      open: this.$store.getters.sidebar,
    };
  },
  mounted() {
    this.open ? this.openNav() : this.closeNav();
    window.addEventListener('resize', this.adjustSidebar);
    // let ele = document.getElementById('wrapper-menu');
    if (window.innerWidth < 768) {
      // ele.setAttribute('style', 'padding-left: 15px !important;');
    }
  },
  methods: {
    adjustSidebar() {
      let ele = document.getElementById('wrapper-menu');
      if (window.innerWidth < 768) {
        // ele.setAttribute('style', 'padding-left: 15px !important;');
      } else if (window.innerWidth >= 768) {
        if (this.open) {
          ele.setAttribute('style', 'padding-left: 220px !important;');
        } else {
          ele.setAttribute('style', 'padding-left: 52px !important;');
        }
      }
    },
    active(subsection) {
      const { path } = this.$route;
      const pathArray = path.split('/');
      const routeSubSection = pathArray[2];
      return routeSubSection == subsection ? true : false;
    },
    toggleNav() {
      this.open ? this.closeNav() : this.openNav();
      this.open = !this.open;
      this.$store.commit('sidebar', this.open);
    },
    openNav() {
      document.getElementById('sidebar-menu').style.width = '220px';
      document.getElementsByClassName('wrapper-menu')[0].style.paddingLeft =
        '220px';
    },
    closeNav() {
      document.getElementById('sidebar-menu').style.width = '52px';
      // document.getElementsByClassName('wrapper-menu')[0].style.paddingLeft =
      //   '60px';

      let ele = document.getElementById('wrapper-menu');
      ele.setAttribute('style', 'padding-left: 60px !important;');
    },
  },
};
</script>
<style scoped>
</style>
